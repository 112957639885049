<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary">
            <!-- pageHeader -->
            <v-row>
              <v-col cols="12" md="8">
                <h1 class="headline font-weight-medium">
                  View Inventory As A Traveler
                </h1>
                <p class="body-1 mt-3">
                  This page displays inventory as a traveler would see it
                </p>
                <p class="body-1 mt-3">Select a package code to begin</p>
                <div style="max-width: 400px">
                  <v-autocomplete
                    label="Select or Type in Package Code"
                    dense
                    outlined
                    :loading="loading"
                    :disabled="loading"
                    :items="retailPackages"
                    item-text="packageCode"
                    return-object
                    @change="onSelectPackageCode"
                    v-model="selectedPackage"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" md="4" align-self="center">
                <v-btn
                  color="primary"
                  depressed
                  class="mb-4"
                  @click="$router.go(-1)"
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Back</v-btn
                >
                <v-switch
                  v-model="adminView"
                  label="View as Admin"
                  class="ma-0 pr-6"
                  :loading="loading"
                  :disabled="loading"
                  dense
                ></v-switch>
                <p class="caption">
                  Enabling admin view will display all listings and all hidden
                  columns
                </p>
                <div class="caption">
                  <span class="d-block">
                    Property ID's assigned to this package code:
                    {{ numberOfPropertyIDs }}</span
                  >
                  <span class="d-block"
                    >All inventory listings in database:
                    {{ items && items.length ? items.length : 0 }}</span
                  >
                  <span class="d-block">
                    Listings displayed to traveler:
                    {{
                      filteredInventoryListings &&
                      filteredInventoryListings.length
                        ? filteredInventoryListings.filter(
                            (i) => i.wouldDisplay
                          ).length
                        : 0
                    }}</span
                  >
                </div>
              </v-col>
            </v-row>
            <!-- pageHeader -->

            <v-row class="mt-6">
              <!-- table -->
              <v-col cols="12" v-if="items.length">
                <v-data-table
                  :items="filteredInventoryListings"
                  :items-per-page="-1"
                  :headers="headers"
                  fixed-header
                  :height="600"
                >
                  <template v-slot:item="data">
                    <tr
                      @click="
                        $router.push('/inventory/' + data.item.resortPropertyId)
                      "
                    >
                      <td>
                        {{ data.item.destination }}
                      </td>
                      <td>
                        {{ data.item.resortName }}
                      </td>
                      <td>
                        {{ formatDate(data.item.checkInDate) }}
                      </td>
                      <td>
                        {{ formatDate(data.item.checkOutDate) }}
                      </td>
                      <td>
                        {{ data.item.numberOfNights }}
                      </td>
                      <td>
                        {{ data.item.bedroomsDisplay }}
                      </td>
                      <td>
                        {{ data.item.maxOccupancy }}
                      </td>
                      <td>
                        {{ data.item.kitchen }}
                      </td>

                      <td v-if="adminView">
                        {{
                          "(" +
                            data.item.peakSeasonRanges[0] +
                            ", " +
                            data.item.peakSeasonRanges[1] +
                            ")" +
                            " (" +
                            data.item.peakSeasonRanges[2] +
                            ", " +
                            data.item.peakSeasonRanges[3] +
                            ")"
                        }}
                      </td>
                      <td>
                        {{ data.item.peakSeasonSurchargeAmount }}
                      </td>
                      <td>
                        {{ data.item.highOccupancySurchargeAmount }}
                      </td>
                      <td>
                        {{ data.item.totalSurcharges }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.costToOrg }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.totalIncome }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.commissionsPaid }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.roomCost }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.taxesAndFees }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.totalCost }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.netProfit }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.minimumProfitAccepted }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.wouldDisplay }}
                      </td>
                      <td v-if="adminView">
                        {{ data.item.source }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <!-- table -->
              <!-- feedback -->
              <v-col cols="12">
                <v-alert
                  color="error"
                  text
                  outlined
                  icon="mdi-information-outline"
                  v-if="noInventoryPropertyIDs"
                  class="text-center"
                  ><span class="font-weight-bold text-uppercase">{{
                    selectedPackage.packageCode
                  }}</span>

                  does not have any resort ID's assigned to it</v-alert
                >
                <v-alert
                  color="info"
                  text
                  outlined
                  icon="mdi-alert-outline"
                  v-if="
                    hasSearched &&
                      items &&
                      !items.length &&
                      !noInventoryPropertyIDs
                  "
                  class="text-center"
                  >The filters applied have removed all listings that the
                  traveler would see</v-alert
                >
                <v-alert
                  color="info"
                  text
                  outlined
                  icon="mdi-alert-outline"
                  v-if="!hasSearched"
                  class="text-center"
                  >Select a package code to view options as a traveler would see
                  them
                </v-alert>
              </v-col>
              <!-- feedback -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { portalsApp } from "@/firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
export default {
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    // bind to retails packages (v-select)
    await this.$bind(
      "retailPackages",
      portalsApp.firestore().collection("retailPackages")
    );
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    adminView: false,
    retailPackages: [],
    selectedPackage: "",
    numberOfPropertyIDs: 0,
    hasSearched: false,
    noInventoryPropertyIDs: false,
    items: [],
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    filteredInventoryListings() {
      let items = this.items.sort((a, b) => {
        if (a.sortableCheckInDate === b.sortableCheckInDate) {
          return b.netProfit - a.netProfit;
        }
        return a.sortableCheckInDate - b.sortableCheckInDate;
      });

      if (this.adminView) {
        return items;
      } else {
        return items.filter((i) => i.wouldDisplay);
      }
    },
    headers() {
      if (this.adminView)
        return [
          { text: "Destination", value: "destination" },
          { text: "Resort Name", value: "resortName" },
          { text: "Check In Date", value: "checkInDate" },
          { text: "Check Out Date", value: "checkOutDate" },
          { text: "# Nights", value: "numberOfNights" },
          { text: "Bedrooms", value: "bedrooms" },
          { text: "Max Occupancy", value: "maxOccupancy" },
          { text: "Kitchen", value: "kitchen" },
          { text: "Peak Season Ranges", value: "peakSeasonRanges" },
          { text: "Peak Season Surcharge", value: "peakSeasonSurchargeAmount" },
          {
            text: "High Occupancy Surcharge",
            value: "highOccupancySurchargeAmount",
          },
          { text: "Total Surcharges", value: "totalSurcharges" },
          { text: "Cost to Org", value: "costToOrg" },
          { text: "Total Income", value: "totalIncome" },
          { text: "Commissions Paid", value: "commissionsPaid" },
          { text: "Unit Cost", value: "roomCost" },
          { text: "Taxes and Fees", value: "taxesAndFees" },
          { text: "Total Cost", value: "totalCost" },
          { text: "Net Profit", value: "netProfit" },
          { text: "Target Profit", value: "minimumProfitAccepted" },
          { text: "Would Display", value: "wouldDisplay" },
          { text: "Source", value: "source" },
        ];
      return [
        { text: "Destination", value: "destination" },
        { text: "Resort Name", value: "resortName" },
        { text: "Check In Date", value: "checkInDate" },
        { text: "Check Out Date", value: "checkOutDate" },
        { text: "# Nights", value: "numberOfNights" },
        { text: "Bedrooms", value: "bedrooms" },
        { text: "Max Occupancy", value: "maxOccupancy" },
        { text: "Kitchen", value: "kitchen" },

        { text: "Peak Season Surcharge", value: "peakSeasonSurchargeAmount" },
        {
          text: "High Occupancy Surcharge",
          value: "highOccupancySurchargeAmount",
        },
        { text: "Total Surcharges", value: "totalSurcharges" },
      ];
    },
  },
  methods: {
    async onSelectPackageCode() {
      await this.$store.dispatch("setLoading", true);
      this.items = [];
      this.hasSearched = true;
      this.noInventoryPropertyIDs = false;
      this.adminView = false;

      // no items
      if (!this.selectedPackage.inventoryProperties.length) {
        this.numberOfPropertyIDs = 0;
        this.noInventoryPropertyIDs = true;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      this.numberOfPropertyIDs = this.selectedPackage.inventoryProperties.length;

      // get retail package
      // extract inventoryProperties
      let retailPackage = portalsApp
        .firestore()
        .collection("retailPackages")
        .doc(this.selectedPackage.packageCode);
      retailPackage = await retailPackage.get();
      let inventoryProperties = retailPackage.data().inventoryProperties;

      // loop over inventoryProperties
      // push to function to create inventory items
      for (let index = 0; index < inventoryProperties.length; index++) {
        const item = inventoryProperties[index];
        this.constructInventoryItem(item);
      }
    },
    async constructInventoryItem(item) {
      // get retail package for costToOrg && bedrooms
      let retailPackage = portalsApp
        .firestore()
        .collection("retailPackages")
        .doc(this.selectedPackage.packageCode);
      retailPackage = await retailPackage.get();
      retailPackage = retailPackage.data();

      ///// Update Peak Season Dates ////

      // get inventory property
      let propertyCheck = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(item);

      propertyCheck = await propertyCheck.get();

      let checkPeakSeasonDateValid = propertyCheck.data();
      if (
        checkPeakSeasonDateValid.hasPeakSeasonSurcharge1 &&
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge1Range") &&
        checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1] <
          Moment().format("YYYY-MM-DD")
      ) {
        let range =
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1]).format(
            "YYYY"
          ) -
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0]).format(
            "YYYY"
          );

        if (!range) {
          const isBefore =
            Moment(
              checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1]
            ).format("MM-DD") < Moment().format("MM-DD");

          if (isBefore) {
            checkPeakSeasonDateValid.peakSeasonSurcharge1Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
            ];
          } else {
            checkPeakSeasonDateValid.peakSeasonSurcharge1Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
            ];
          }
        } else {
          checkPeakSeasonDateValid.peakSeasonSurcharge1Range = [
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0])
              .year(Moment().format("YYYY"))
              .format("YYYY-MM-DD"),
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1])
              .year(Moment().format("YYYY") * 1 + range)
              .format("YYYY-MM-DD"),
          ];
        }
      }
      if (
        checkPeakSeasonDateValid.hasPeakSeasonSurcharge2 &&
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge2Range") &&
        checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1] <
          Moment().format("YYYY-MM-DD")
      ) {
        let range =
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1]).format(
            "YYYY"
          ) -
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0]).format(
            "YYYY"
          );
        if (!range) {
          const isBefore =
            Moment(
              checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1]
            ).format("MM-DD") < Moment().format("MM-DD");

          if (isBefore) {
            checkPeakSeasonDateValid.peakSeasonSurcharge2Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
            ];
          } else {
            checkPeakSeasonDateValid.peakSeasonSurcharge2Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
            ];
          }
        } else {
          checkPeakSeasonDateValid.peakSeasonSurcharge2Range = [
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0])
              .year(Moment().format("YYYY"))
              .format("YYYY-MM-DD"),
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1])
              .year(Moment().format("YYYY") * 1 + range)
              .format("YYYY-MM-DD"),
          ];
        }
      }
      if (
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge2Range") ||
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge1Range")
      ) {
        let inventoryDocRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(item);

        await inventoryDocRef.update({
          peakSeasonSurcharge1Range:
            checkPeakSeasonDateValid.peakSeasonSurcharge1Range,
          peakSeasonSurcharge2Range:
            checkPeakSeasonDateValid.peakSeasonSurcharge2Range,
        });
      }

      ///// Update Peak Season Dates ////

      // get inventory property
      let property = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(item);
      property = await property.get();
      property = property.data();

      // add costToOrg && bedrooms
      property.costToOrg = retailPackage.costToOrg;
      property.retailBedrooms = retailPackage.bedrooms;

      // reduce to values needed for table
      delete property.archived;
      delete property.activities;
      delete property.airportInfo;
      delete property.amenities;
      delete property.checkInInfo;
      delete property.dateSubmitted;
      delete property.dateUpdated;
      delete property.destination;
      delete property.id;
      delete property["image-src"];
      delete property.otherInfo;
      delete property.overview;
      delete property.resortAddress;
      delete property.resortName;
      delete property.resortPropertyId;
      delete property.restaurants;
      delete property.restrictions;
      delete property.source;
      delete property.template;
      delete property.unitInfo;

      // get inventory items
      let listing = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(item);
      listing = await listing.get();
      let items = listing.data().items;

      // removing any items less than 45 days from today
      let twoWeeksFromToday = moment()
        .startOf("day")
        .add(45, "d");
      items = items.filter((i) => {
        let isBefore = moment(i.checkInDate).isBefore(twoWeeksFromToday);
        if (!isBefore) return i;
      });

      // add property values to listing item
      items.forEach((i) => {
        i.commissionsPaidPercentage = property.commissionsPaidPercentage;
        i.costOfExtras = property.costOfExtras;
        i.costToOrg = property.costToOrg;
        i.highOccupancySurchargeAmount = property.highOccupancySurchargeAmount;
        i.minimumProfitAccepted = property.minimumProfitAccepted;
        i.peakSeasonSurcharge1Range = property.peakSeasonSurcharge1Range;
        i.peakSeasonSurcharge2Range = property.peakSeasonSurcharge2Range;
        i.peakSeasonSurchargeAmount = property.peakSeasonSurchargeAmount;
        i.retailBedrooms = property.retailBedrooms;
        i.taxesFeesPercentage = property.taxesFeesPercentage;
      });

      // format items
      items.forEach((i) => {
        // sortable checkInDate for table sorting
        i.sortableCheckInDate = i.checkInDate.replace(/-/g, "").trim();
        // create peakSeasonRanges for UI table display
        let peakSeasonRanges = [];
        i.peakSeasonSurcharge1Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonSurcharge2Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonRanges = peakSeasonRanges;
        // create moment peakSeason ranges
        let momentPeakSeason1Range = moment.range(
          i.peakSeasonSurcharge1Range[0],
          i.peakSeasonSurcharge1Range[1]
        );
        let momentPeakSeason2Range = moment.range(
          i.peakSeasonSurcharge2Range[0],
          i.peakSeasonSurcharge2Range[1]
        );
        // calc peakSeasonSurchargeAmount
        let originalPeakSeasonSurchargeAmount = i.peakSeasonSurchargeAmount;
        i.peakSeasonSurchargeAmount = 0;
        if (
          momentPeakSeason1Range.contains(moment(i.checkInDate)) ||
          momentPeakSeason2Range.contains(moment(i.checkInDate))
        ) {
          i.peakSeasonSurchargeAmount = originalPeakSeasonSurchargeAmount;
        }
        // highOccupancy surcharge calculation
        if (i.retailBedrooms >= i.bedrooms) i.highOccupancySurchargeAmount = 0;

        i.totalSurcharges =
          i.peakSeasonSurchargeAmount + i.highOccupancySurchargeAmount;
        i.totalIncome = i.costToOrg + i.totalSurcharges;
        i.commissionsPaid = (i.commissionsPaidPercentage / 100) * i.costToOrg;
        i.commissionsPaid = parseInt(i.commissionsPaid);
        i.taxesAndFees = (i.roomCost / 100) * i.taxesFeesPercentage;
        i.taxesAndFees = parseInt(i.taxesAndFees);
        i.totalCost = i.commissionsPaid + i.roomCost + i.taxesAndFees;
        i.netProfit = i.totalIncome - i.totalCost;
        let wouldDisplay = false;
        if (i.netProfit >= i.minimumProfitAccepted) wouldDisplay = true;
        i.wouldDisplay = wouldDisplay;
        // push to items
        // items then filtered @ computed
        this.items.push(i);
      });

      await this.$store.dispatch("setLoading", false);
    },
  },
};
</script>

<style scoped>
tr {
  cursor: pointer;
  user-select: none !important;
}
/* td {
  height: 36px !important;
  width: 1px;
  white-space: nowrap;
} */
</style>
